<template>
    <div>
        <modal :name="modal_section" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">ADD ADD-ON SECTION</span>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <form>
                            <div class="form-group mt-0 contactGroup">
                                <input
                                    class="inputContact"
                                    v-validate="'required'"
                                    type="text"
                                    name="addonsectionname"
                                    autofocus
                                    required
                                    @blur="checkNameExists()"
                                    v-model="addonsection.name"
                                    :maxlength="addonsectionname"
                                    id="section_name_create_section"
                                />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">
                                    Add-On Section Name
                                    <sup>★</sup>
                                </label>
                                <span class="invalid-feedback-form" v-show="errors.has('addonsectionname')" style="display:inline;">{{errors.first('addonsectionname')}}</span>
                                <span
                                    class="invalid-feedback-form"
                                    style="display: inline;"
                                    v-if="isAddonNameExists && addonsection.name.trim()">Add-On Section Name Exists</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{addonsectionname - addonsection.name.length}}/ {{ addonsectionname }}</p>
                            </div>
                            <div class="form-group contactGroup">
                                <textarea
                                    class="inputContact mt-3"
                                    type="text"
                                    v-model="addonsection.description"
                                    :maxlength="addonsectiondesc"
                                    required
                                    style="padding: 5px 10px 2px 0px!important;margin-top: 16px!important;"
                                    id="desc_create_section"
                                ></textarea>
                                <span class="highlightContact"></span>
                                <label class="labelContact">Description</label>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{addonsectiondesc - addonsection.description.length}}/ {{ addonsectiondesc }}</p>
                            </div>
                        </form>
                        <div class="v-modal-footer text-center mt-8">
                            <a class="btn btn-link btn-smm" @click="cancelSection">CANCEL</a>
                            <button class="btn btn-secondary btn-smm text-uppercase" @click="save()">
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import addonSection from './mixins/addonsection'
export default {
    props: ['modal_section'],
    mixins: [addonSection],
    data() {
        return {
            addonsectionname: 45,
            addonsectiondesc: 1000,
            addonsection: {
                name: '',
                description: '',
                status: true
            },
            isAddonNameExists: false
        }
    },
    methods: {
        cancelSection() {
            this.addonsection = {
                name: '',
                description: '',
                status: true
            }
            this.$modal.hide('addon-create-section')
        },
        checkNameExists() {
            this.$http
                .post('/add_ons/section/check', {
                    name: this.addonsection.name
                })
                .then(response => {
                    if (response.data.status_id == 0) {
                        this.isAddonNameExists = true
                    } else {
                        this.isAddonNameExists = false
                    }
                })
        },
        save() {
            this.$validator.validateAll().then(result => {
                if (result && !this.isAddonNameExists) {
                    this.addAddonSection()
                }
            })
        }
    },
    created() {
        const dict = {
            custom: {
                addonsectionname: {
                    required: () => 'Add-On Section Name is Required'
                }
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
